@mixin list-reset($margin: 0, $padding: 0, $li-margin: 0, $li-padding: 0, $background: transparent) {
	margin: $margin;
	padding: $padding;

	li {
		list-style: none;
		background: $background;
		margin: $li-margin;
		padding: $li-padding;
	}
}

%list-reset {
	@include list-reset();
}

@mixin pseudo($display: block, $position: absolute, $content: "") {
	content: $content;
	display: $display;
	position: $position;
}
@mixin fontello() {
	font-family: $flexee-fontello-font-family;
	font-style: normal;
	font-weight: normal;
	speak: none;
	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: 0.2em;
	text-align: center;
	font-variant: normal;
	text-transform: none;
	line-height: 1em;
	margin-left: 0.2em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin pseudo-line() {
    position: relative;
	&:before {
		@include pseudo();
		height: 1px;
		background: $flexee-layout-border-color;
		left: 10px;
        right: 10px;
        @content;
	}
}